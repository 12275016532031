
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import Seo from '../components/util/seo';
import { capitalize } from '../util/stringUtils';

function Home() {
  const router = useRouter();
  const { t } = useTranslation('home');
  const { t: th } = useTranslation('header');

  return (
    <>
      <Seo title={`LaserFlare ${capitalize(th('marketplace'))}`} description={t('shortDescription')} keywords="laserflaremarket market lfm madfox laserflare nextjs next reactjs react graphql apollo tailwind nodejs mongo" ogType="website" canonical={router.asPath} />
      <svg className="z-10 top-16 absolute" id="visual" viewBox="0 0 960 54" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1">
        <path
          d="M0 20L22.8 20.8C45.7 21.7 91.3 23.3 137 23.3C182.7 23.3 228.3 21.7 274 20.8C319.7 20 365.3 20 411.2 21.3C457 22.7 503 25.3 548.8 27.5C594.7 29.7 640.3 31.3 686 31.3C731.7 31.3 777.3 29.7 823 28.5C868.7 27.3 914.3 26.7 937.2 26.3L960 26L960 0L937.2 0C914.3 0 868.7 0 823 0C777.3 0 731.7 0 686 0C640.3 0 594.7 0 548.8 0C503 0 457 0 411.2 0C365.3 0 319.7 0 274 0C228.3 0 182.7 0 137 0C91.3 0 45.7 0 22.8 0L0 0Z"
          className="fill-current text-th-navbar"
          strokeLinecap="round"
          strokeLinejoin="miter"
        />
      </svg>
      <h1 className="text-center text-xl font-semibold text-th-primary-medium mt-32">LaserFlare Market is a modern marketplace with unique features for Minecraft servers.</h1>
    </>
  );
}

export default Home;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  